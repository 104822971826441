import leaflet from 'leaflet';
import 'leaflet-editable';
import 'leaflet-fullscreen';
import 'leaflet.markercluster';
import { cr, ap } from '../lib/ui/dom';
import { translate as t } from './../util/i18n';
import loadStyle from '../lib/util/loadStyle';
import is from '../lib/util/is';
import safeGet from '../lib/util/safeGet';
import mapSearch from './mapSearch';
import { redPrimary, redDark } from '../lib/ui/colors';
import button from '../lib/ui/button';
import { hide, show } from '../lib/ui/showAndHide';
import { errLog } from '@/lib/util/logger';

let lastKnownLocation = null;

const locationIcon = leaflet.icon({
  iconUrl: '/static/images/location.svg',
  iconSize: [40, 40],
  iconAnchor: [20, 40],
});

const positionIcon = leaflet.icon({
  iconUrl: '/static/images/position.svg',
  iconSize: [20, 20],
  iconAnchor: [10, 10],
});

const locationIconChecked = leaflet.icon(
  Object.assign({}, locationIcon, {
    iconUrl: '/static/images/location-checked.svg',
  }),
);

const stringToLayer = (geofence) => {
  let layer;

  const parts = geofence.split(':');
  if (parts[0] === 'C') {
    // Circle
    const circleData = parts[1].split(',');
    const lat = circleData[0];
    const lng = circleData[1];
    const radius = circleData[2];

    layer = leaflet.circle(
      {
        lat,
        lng,
      },
      {
        radius,
      },
    );
  } else {
    // Polygon
    const latlngs = parts[1].split(';').map((latlngString) => {
      const latlngParts = latlngString.split(',');
      return {
        lat: latlngParts[0],
        lng: latlngParts[1],
      };
    });

    layer = leaflet.polygon(latlngs);
  }

  return layer;
};

// const layerToShape = (layer) => {
//   if (!is.defined(layer)) {
//     return;
//   }

//   if (is.function(layer.getRadius)) { // Circle
//     const latlng = layer.getLatLng();
//     return {
//       lat: latlng.lat,
//       lng: latlng.lng,
//       radius: layer.getRadius()
//     };
//   } else { // Polygon
//     return layer.getLatLngs();
//   }
// };

const layerToString = (layer) => {
  if (!is.defined(layer)) {
    return;
  }

  if (is.function(layer.getRadius)) {
    // Circle
    const radius = layer.getRadius();
    const latlng = layer.getLatLng();
    return `C:${latlng.lat},${latlng.lng},${radius}`;
  } else {
    // Polygon
    const latlngs = layer.getLatLngs()[0].map((latlng) => latlng.lat + ',' + latlng.lng);

    return 'P:' + latlngs.join(';');
  }
};

/**
 * @typedef DrawTypes
 * @prop {boolean} marker
 * @prop {boolean} line
 * @prop {boolean} rectangle
 * @prop {boolean} polyline
 * @prop {boolean} circlemarker
 */

/**
 * @typedef MapConfig
 * @prop {string} className an extra class name to add to the root element
 * @prop {object} draw a configuration for the draw mechanisms
 * @prop {DrawTypes} draw.types a configuration of the draw types to enable
 * @prop {function} onAutoFitEnabled a callback for when autofit is enabled
 * @prop {boolean} search whether or not to provide search functionality
 * @prop {boolean} fullscreen whether or not to provide fullscreen functionality
 */

/**
 * Creates an interactive map
 *
 * @param {MapConfig} mapConfig the map configuration
 */
const map = (mapConfig) => {
  let currentDrawing = null;

  let className = '';

  if (is.object(mapConfig)) {
    if (is.strNotEmpty(mapConfig.className)) {
      className = ' ' + mapConfig.className;
    }
  }

  const element = cr('div', 'c-map' + className, null);

  const config = {
    worldCopyJump: true,
    zoomControl: false,
    editable: true,
    dragging: true,
  };

  if (mapConfig?.interactive === false) {
    config.dragging = false;
    config.boxZoom = false;
    config.doubleClickZoom = false;
    (config.keyboard = false), (config.scrollWheelZoom = false);
    config.tap = false;
    config.touchZoom = false;
  }

  const leafletMap = leaflet.map(element, config);

  const locationsLayer = new leaflet.FeatureGroup();
  const positionsCircleLayer = new leaflet.FeatureGroup();
  const positionsLayer = new leaflet.markerClusterGroup({
    spiderfyOnMaxZoom: true,
    iconCreateFunction: (cluster) =>
      leaflet.divIcon({
        html: cr('span', 'leaflet-cluster-icon', cluster.getChildCount()).outerHTML,
      }),
  });
  leafletMap.addLayer(locationsLayer);
  leafletMap.addLayer(positionsCircleLayer);
  leafletMap.addLayer(positionsLayer);

  const startDrawing = (type) => {
    if (currentDrawing) {
      currentDrawing.remove();
    }

    switch (type) {
      case 'polygon':
        currentDrawing = leafletMap.editTools.startPolygon();
        break;
      case 'circle':
      default:
        currentDrawing = leafletMap.editTools.startCircle();
    }
  };

  const getCurrentDrawing = () => currentDrawing;

  const getCurrentDrawingAsString = () => layerToString(currentDrawing);

  const setCurrentDrawingFromString = (drawingString, performFlyTo) => {
    if (!drawingString) {
      return;
    }

    currentDrawing = stringToLayer(drawingString);
    currentDrawing.addTo(leafletMap);

    if (performFlyTo !== false) {
      fitBounds(currentDrawing.getBounds());
    }
  };

  const clearDrawing = () => {
    if (currentDrawing) {
      currentDrawing.remove();
      currentDrawing = null;
    }
  };

  const startEditDrawing = () => {
    if (currentDrawing) {
      currentDrawing.enableEdit();
    }
  };

  const stopEditDrawing = () => {
    if (currentDrawing) {
      currentDrawing.disableEdit();
    }
  };

  let autoFit = true;
  const isAutoFitEnabled = () => autoFit;
  const setAutoFit = (auto) => {
    autoFit = auto;
    if (autoFit) {
      hide(autoFitButton);
    } else {
      show(autoFitButton);
    }
  };

  const setAutoFitEnabled = () => {
    setAutoFit(true);
    const onAutoFitEnabled = safeGet(mapConfig, 'onAutoFitEnabled');
    if (is.function(onAutoFitEnabled)) {
      onAutoFitEnabled();
    }
  };
  const autoFitButton = button(setAutoFitEnabled, 'auto-fit-button', t('autoFit'));
  hide(autoFitButton);

  if (is.function(safeGet(mapConfig, 'onAutoFitEnabled'))) {
    ap(element, autoFitButton);
  }

  let isProgrammaticMove = false;
  leafletMap.on('zoomstart movestart', () => {
    if (!isProgrammaticMove) {
      setAutoFit(false);
    }
  });

  let locations = [];

  const getLocations = () => locations;

  const setRadiusCircleVisibility = (circle, visible) => {
    circle.setStyle({
      opacity: visible ? 1 : 0,
      fillOpacity: visible ? 0.2 : 0,
    });
  };

  let positions = {};

  const addPosition = (position) => {
    const circle = leaflet
      .circle(position.latLng, {
        radius: position.latLng.radius,
        fill: redPrimary,
        color: redDark,
      })
      .addTo(positionsCircleLayer);
    const marker = leaflet.marker(position.latLng, {
      icon: positionIcon,
      title: position.name,
      alt: position.name,
    });
    positionsLayer.addLayer(marker);
    const index = positionsLayer.getLayers().length - 1;

    if (is.defined(position.popupContent)) {
      marker.bindPopup(position.popupContent);
    }

    setRadiusCircleVisibility(circle, false);

    marker.on('click', () => {
      positionsCircleLayer.getLayers().forEach((otherRadiusCircle) => {
        setRadiusCircleVisibility(otherRadiusCircle, false);
      });
      setRadiusCircleVisibility(circle, true);

      if (is.defined(position.onClick)) {
        position.onClick();
      }
    });

    const positionObject = {
      index,
      circle,
      marker,
      openPopup: () => marker.openPopup(),
    };

    if (position.id) {
      positions[position.id] = positionObject;
    }

    return positionObject;
  };

  const getPosition = (index) => {
    const circle = positionsCircleLayer.getLayers()[index];
    const marker = positionsLayer.getLayers()[index];
    return {
      circle,
      marker,
      openPopup: () => marker.openPopup(),
    };
  };

  const getPositions = () => {};

  const clearPositions = () => {
    positions = {};
    positionsCircleLayer.clearLayers();
    positionsLayer.clearLayers();
  };

  const fitPositions = (maxZoom) => {
    if (positionsLayer.getLayers().length > 0) {
      fitBounds(positionsCircleLayer.getBounds(), maxZoom);
    }
  };

  const fitPosition = (index) => {
    const position = positionsCircleLayer.getLayers()[index];
    const latLng = position.getLatLng();
    flyTo(latLng, 17);
  };

  const showPositionById = (id) => {
    const position = positions[id];
    const latLng = position.marker.getLatLng();
    flyTo(latLng, 17);
    position.openPopup();
  };

  const addLocation = (location, flyTo) => {
    location = Object.assign({}, location);
    if (!location.geofence || location.geofence === '') {
      return;
    }

    const layer = stringToLayer(location.geofence);
    location.layer = layer;

    locationsLayer.addLayer(layer);

    if (flyTo !== false) {
      fitBounds(layer.getBounds());
    }

    let lMarker;

    if (is.object(location.marker)) {
      const center = layer.getCenter ? layer.getCenter() : layer.getLatLng();
      lMarker = leaflet
        .marker(center, {
          icon: locationIcon,
          title: location.marker.name,
          alt: location.marker.name,
        })
        .addTo(leafletMap);
      if (is.function(location.marker.onClick)) {
        lMarker.on('click', location.marker.onClick);
      }
      location.marker = lMarker;
    }

    location.setChecked = (checked) => {
      if (lMarker) {
        lMarker.setIcon(checked ? locationIconChecked : locationIcon);
      }
    };

    location.setChecked(location.checked);

    if (is.function(location.onClick)) {
      layer.on('click', location.onClick);
    }

    locations.push(location);
  };

  const clearLocations = () => {
    locations.forEach((location) => {
      if (location.marker) {
        location.marker.remove();
      }
    });
    locations = [];
    locationsLayer.clearLayers();
  };

  const clearAll = () => {
    clearDrawing();
    clearLocations();
    clearPositions();
  };

  const fitLocations = () => {
    fitBounds(locationsLayer.getBounds());
  };

  const fitBounds = (bounds, maxZoom) => {
    if (!bounds.isValid()) {
      return;
    }

    isProgrammaticMove = true;
    leafletMap.flyToBounds(bounds, {
      animate: true,
      duration: 0.5,
      maxZoom: is.number(maxZoom) ? maxZoom : 17,
      padding: [100, 100],
    });
    setTimeout(() => (isProgrammaticMove = false), 50);
  };

  const flyTo = (latLng, zoom) => {
    isProgrammaticMove = true;
    leafletMap.flyTo(latLng, zoom, {
      animate: true,
      duration: 0.5,
      maxZoom: 17,
    });
    setTimeout(() => (isProgrammaticMove = false), 50);
  };

  const redrawTiles = () => leafletMap.invalidateSize();

  const isPopulated = () => {
    let layerItems = 0;

    [locationsLayer, positionsLayer].forEach((layerCollection) => {
      layerItems += layerCollection.getLayers().length;
    });

    layerItems += currentDrawing ? 1 : 0;

    return layerItems > 0;
  };

  const setView = (latLng, zoom) => {
    isProgrammaticMove = true;
    leafletMap.setView(latLng, zoom);
    setTimeout(() => (isProgrammaticMove = false), 50);
  };

  const setWorldView = () => {
    setView([0, 0], 2.5);
  };

  const mapLight = 'ckgam9wol3e0p19nwr1t1r03x';
  // const mapDark = 'ckgao0fya21s71as1bjcp5lon';
  const accessToken =
    'pk.eyJ1IjoibGFyc2FjMDciLCJhIjoiY2pmOGEzbms3MnZ6NTJ3b203Ympvb3R6ayJ9.ah9AMX3fXc7CGTiUUHgkzA';

  const loaded = Promise.all([
    loadStyle('https://unpkg.com/leaflet@1.3.1/dist/leaflet.css').then(() => {
      leaflet
        .tileLayer(
          `https://api.mapbox.com/styles/v1/larsac07/${mapLight}/tiles/{z}/{x}/{y}?access_token=${accessToken}`,
          {
            attribution:
              '© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            id: 'mapbox.streets',
            maxZoom: 20,
          },
        )
        .addTo(leafletMap);

      // If there is a last known location, go to it
      if (lastKnownLocation != null) {
        setView([lastKnownLocation.coords.latitude, lastKnownLocation.coords.longitude], 7);

        // If we can request geolocation and it is accepted, use the current location
      } else if (navigator.geolocation) {
        // Start with showing a world view while we wait for current location
        setWorldView();

        navigator.geolocation.getCurrentPosition(
          (position) => {
            // Save last known location
            lastKnownLocation = position;

            // Before going to the current location, check that the map has not been populated
            if (!isPopulated()) {
              setView([position.coords.latitude, position.coords.longitude], 7);
            }
          },
          (error) => {
            errLog(error);
          },
        );
      } else {
        setWorldView();
      }
    }),
    loadStyle(
      `https://api.mapbox.com/mapbox.js/plugins/leaflet-fullscreen/v1.0.1/leaflet.fullscreen.css`,
    ),
    loadStyle(`https://unpkg.com/leaflet.markercluster@1.3.0/dist/MarkerCluster.css`),
    loadStyle(`https://unpkg.com/leaflet.markercluster@1.3.0/dist/MarkerCluster.Default.css`),
  ]);

  // Leaflet draw event logging
  // [
  //   'draw:created',
  //   'draw:edited',
  //   'draw:deleted',
  //   'draw:drawstart',
  //   'draw:drawstop',
  //   'draw:drawvertex',
  //   'draw:editstart',
  //   'draw:editmove',
  //   'draw:editresize',
  //   'draw:editvertex',
  //   'draw:editstop',
  //   'draw:deletestart',
  //   'draw:deletestop',
  //   'draw:toolbaropened',
  //   'draw:toolbarclosed',
  //   'draw:markercontext'
  // ].forEach((eventName) => {
  //   leafletMap.on(eventName, (event) => {
  //     console.log(eventName, event);
  //   });
  // });

  const api = {
    addLocation,
    addPosition,
    clearAll,
    clearLocations,
    clearPositions,
    element,
    fitPositions,
    fitPosition,
    fitLocations,
    fitBounds,
    flyTo,
    getLocations,
    getPosition,
    getPositions,
    isAutoFitEnabled,
    loaded,
    on: (eventType, callback) => leafletMap.on(eventType, callback),
    redrawTiles,
    showPositionById,
    startDrawing,
    getCurrentDrawing,
    getCurrentDrawingAsString,
    setCurrentDrawingFromString,
    clearDrawing,
    startEditDrawing,
    stopEditDrawing,
  };

  if (is.object(mapConfig)) {
    if (mapConfig.search) {
      mapSearch(api).then((searchElement) => {
        ap(element, searchElement);
      });
    }

    if (mapConfig.fullscreen) {
      leafletMap.addControl(new leaflet.Control.Fullscreen());
    }
  }

  return api;
};

export default map;
