import { ap, cr, on, q } from './../ui/dom';

const styles = {};

/**
 * Loads a css reference into the document if its source has not been used before.
 *
 * @param  {string} href             the href location of the css document
 * @param  {HTMLDomElement} parent  the parent to append the css to
 *                                  (optional). Defaults to head.
 * @return {Promise}                A Promise which resolves when the script has
 *                                  loaded, or rejects if it has already been
 *                                  loaded.
 */
const loadStyle = (href, parent) =>
  new Promise((resolve) => {
    if (styles[href]) {
      // Do not add if already loaded
      resolve();
    } else {
      styles[href] = true; // Mark href for later

      const link = cr('link');
      parent = parent || q('head');

      link.href = href;
      link.rel = 'stylesheet';

      on(link, 'load', () => {
        resolve();
      });

      ap(parent, link);
    }
  });

export default loadStyle;
