import { ap, cr, on, q } from './../ui/dom';

const scripts = {};

/**
 * Loads a script into the document if its source has not been used before.
 *
 * @param  {string} src              the src location of the script
 * @param  {HTMLDomElement} [parent] the parent to append the script to
 *                                   (optional). Defaults to head.
 * @return {Promise}                 A Promise which resolves when the script
 *                                   has loaded, or rejects if it has already
 *                                   been loaded.
 */
const loadScript = (src, parent) =>
  new Promise((resolve) => {
    if (scripts[src]) {
      // Do not add if already loaded
      resolve();
    } else {
      scripts[src] = true; // Mark src for later

      const script = cr('script');
      parent = parent || q('head');

      script.src = src;
      script.async = true;
      script.defer = true;

      on(script, 'load', () => {
        resolve();
      });

      ap(parent, script);
    }
  });

export default loadScript;
