import { translate as t } from './../util/i18n';
import messageDialog from '@/components/dialog/messageDialog';
import textInput from './../lib/ui/textInput';
import { ap, cr, ac, dc } from './../lib/ui/dom';
import loadScript from '../lib/util/loadScript';
import button from '../lib/ui/button';
import i from '../lib/ui/i';
import form from '../lib/ui/form';
import is from '../lib/util/is';
import appSettings from '@/appSettings';

// const geometryToLatLng = (geometry) => {
//   const loc = geometry.location;
//   const lat = loc.lat();
//   const lng = loc.lng();
//   return {
//     lat,
//     lng
//   };
// };

const geometryToBounds = (geometry) => {
  const viewport = geometry.viewport;
  const sw = viewport.getSouthWest();
  const ne = viewport.getNorthEast();
  return [
    [sw.lat(), sw.lng()],
    [ne.lat(), sw.lng()],
  ];
};

const mapSearch = (mapApi) =>
  new Promise((resolve, reject) => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${appSettings.googleApiKey}&libraries=places`,
    )
      .then(() => {
        const search = () => {
          if (is.strNotEmpty(searchText)) {
            hideSearchInput();

            const p = autoComplete.getPlace() || { name: searchText };

            new google.maps.Geocoder().geocode(
              {
                address: p.name,
                region: 'no',
              },
              (res, status) => {
                if (status === 'ZERO_RESULTS') {
                  messageDialog(
                    t('mapSearchNoResults'),
                    t('mapSearchNoResultsL', null, {
                      search: p.name,
                    }),
                    t('close'),
                  ).open();
                } else if (status !== 'OK') {
                  messageDialog(
                    t('mapSearchError'),
                    t('mapSearchErrorL', null, {
                      search: p.name,
                      status,
                    }),
                    t('close'),
                  ).open();
                } else {
                  mapApi.fitBounds(geometryToBounds(res[0].geometry));
                }
              },
            );
          }
        };

        let searchOpen = false;

        const clearSearchInput = () => {
          searchText = '';
          searchInput.setValue('');
        };

        const openSearchInput = () => {
          ac(searchForm, 'show');
          searchInput.inputElement.focus();
          searchOpen = true;
        };

        const hideSearchInput = () => {
          dc(searchForm, 'show');
          searchOpen = false;
          clearSearchInput();
        };

        const searchForm = form(search, 'c-search-form');

        let searchText;
        const searchInput = textInput({
          onChange: (e) => (searchText = e.target.value),
          className: 'c-search-input',
        });

        const hideSearchInputButton = ap(button(hideSearchInput, 'c-hide-search'), i('times'));

        hideSearchInput();

        const autoComplete = new google.maps.places.Autocomplete(searchInput.inputElement);

        const buttonClick = () => {
          if (searchOpen) {
            search();
          } else {
            openSearchInput();
          }
        };

        const searchButton = ap(button(buttonClick, 'c-search-button circle'), i('search'));

        const element = ap(
          cr('div', 'c-map-search'),
          ap(searchForm, searchInput.element, hideSearchInputButton),
          searchButton,
        );

        resolve(element);
      })
      .catch((res) => reject(res));
  });

export default mapSearch;
